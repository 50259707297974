<script>
import {
	authMethods,
	notificationMethods,
} from "@/state/helpers";
import {required} from "vuelidate/lib/validators";
import LogoSvg from "@/components/Landing/LogoSvg.vue";
export default {
	components: {
		LogoSvg
	},
	page: {
		title: "Reset Password",
	},
	data() {
		return {
			submitted: false,
			type: 'password',
			password: '',
			newPassword: '',
		}
	},
	validations: {
		password: {required},
		newPassword: {required},
	},
	computed: {
		notification() {
			return this.$store ? this.$store.state.notification : null;
		},
		notificationAutoCloseDuration() {
			return this.$store && this.$store.state.notification ? 5 : 0;
		},
	},
	methods: {
		...authMethods,
		...notificationMethods,
		changeInputType() {
			if(this.type === 'password') {
				this.type = 'text'
			} else {
				this.type = 'password'
			}
		},
		tryToReset() {
			this.submitted = true;
			// stop here if form is invalid
			this.$v.$touch();

			if (this.$v.$invalid) {
				return;
			} else {
				this.handleResetPassword({
					password: this.password,
					password_confirmation: this.newPassword,
					email: this.$route.query.email,
					token: this.$route.params.token
				})
			}
		}
	}
}
</script>
<template>
	<div class="account-pages my-5 pt-sm-5">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-5">
					<div class="text-center mb-4">
						<router-link to="/" class="auth-logo mb-5 d-block">
							<LogoSvg />
						</router-link>

						<h4>{{ $t('resetpassword.title') }}</h4>
					</div>

					<div class="card">
						<div class="card-body p-4">
							<b-alert
								:variant="notification.type"
								class="mt-3"
								v-if="notification.message"
								:show="notificationAutoCloseDuration"
								dismissible
							>{{ notification.message }}
							</b-alert>
							<div class="p-3">
								<form action="" @submit.prevent="tryToReset">
									<div class="form-group mb-4">
										<label>{{ "Password" }}</label>
										<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
											<div
												class="input-group-prepend"
												style="cursor: pointer;"
												@click="changeInputType">
												<span class="input-group-text border-light text-muted">
													<i
														v-if="type === 'text'"
														class="ri-eye-line"
													></i>
													<i
														v-if="type === 'password'"
														class="ri-eye-off-line"
													></i>
												</span>
											</div>
											<input
												v-model="password"
												:type="type"
												class="form-control bg-soft-light border-light"
												placeholder="Password"
												:class="{ 'is-invalid': submitted && $v.password.$error }"
											/>
											<div v-if="submitted && $v.password.$error" class="invalid-feedback">
												<span
													v-if="!$v.password.required"
												>{{ "Password required" }}}</span>
											</div>
										</div>
									</div>
									<div class="form-group mb-4">
										<label>{{ "Repeat Password" }}</label>
										<div class="input-group mb-3 bg-soft-light input-group-lg rounded-lg">
											<div
												class="input-group-prepend"
												style="cursor: pointer;"
												@click="changeInputType">
												<span class="input-group-text border-light text-muted">
													<i
														v-if="type === 'text'"
														class="ri-eye-line"
													></i>
													<i
														v-if="type === 'password'"
														class="ri-eye-off-line"
													></i>
												</span>
											</div>
											<input
												v-model="newPassword"
												:type="type"
												class="form-control bg-soft-light border-light"
												placeholder="Password"
												:class="{ 'is-invalid': submitted && $v.password.$error }"
											/>
											<div v-if="submitted && $v.newPassword.$error" class="invalid-feedback">
												<span
													v-if="!$v.newPassword.required"
												>{{ "Password required" }}}</span>
											</div>
										</div>
									</div>

									<div>
										<button
											class="btn btn-primary btn-block"
											type="submit"
										>{{ $t('resetpassword.form.button.text') }}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>

					<div class="mt-5 text-center">
						<p>
							{{ $t('resetpassword.signintext') }}
							<router-link
								tag="a"
								to="/login"
								class="font-weight-medium text-primary"
							>{{ $t('resetpassword.signinlink') }}
							</router-link>
						</p>
						<p>
							© {{ new Date().getFullYear() }}
							<i
								class="mdi mdi-heart text-danger"
							></i>
							Romeo
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>